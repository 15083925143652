<template>
    <div class="relative rounded-lg w-fit inline-block" :class="{'border rounded border-red bg-red-dimmed': errorMessage}" :style="colorOverride.bg.grey2">
        <div class="flex items-center gap-6">
            <div class="flex items-center relative" :style="'width: ' + widthInput">
                <div class="px-3 py-1.5 opacity-0 text-lg" aria-hidden="true">
                    {{ valueToDisplay }}
                </div>

                <input id="input"
                       v-model="content"
                       tabindex="0"
                       class="text-lg absolute w-full top-0 left-0 px-3 py-1.5 appearance-none outline-none bg-transparent"
                       :inputmode="type === 'number' ? 'numeric' : inputMode"
                       :class="[{'text-tangerine': !disableTextColor && colorOverride === null}, from === 'maslow' ? 'placeholder:text-grey5' : 'placeholder:text-[#A8A8A8]']"
                       :style="colorOverride !== null ? colorOverride.text.primary : ''"
                       @blur="handleBlur"
                       @keydown.enter.prevent="$emit('enter')"
                       :placeholder="placeholder" @focus="$emit('focus', $event)" @focusout="$emit('focusout', $event)"
                >
            </div>

            <div v-if="showValidationErrors && errorMessage"
                 class="absolute whitespace-nowrap top-8 text-overline mt-0.5 text-red-error form-error-display text-base"
                 :class="{'col-span-12': displayInline}"
            >
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { toRef, computed, watch } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
    vid: {
        type: String,
    },
    type: {
        type: String,
        default: "text"
    },
    inputMode: {
        type: String,
        default: "text"
    },
    placeholder: {
        type: String,
        default: ""
    },
    width: {
        type: String,
        default: "100%"
    },
    widthInput: {
        type: String,
        default: "100%"
    },
    modelValue: {},
    showValidationErrors: {
        type: Boolean,
        default: false
    },
    rules: {
        type: [String,Object],
        default: ""
    },
    immediate: {
        type: Boolean,
        default: false
    },
    displayInline: {
        type: Boolean,
        default: false
    },
    typeFormat: {
        type: Boolean,
        default: true,
    },
    disableTextColor: {
        type: Boolean,
        default: false
    },
    colorOverride: {
        type: Object,
        default: null
    },
    from: {
        type: String,
        default: 'maslow'
    }
});

const vid = toRef(props, 'vid');

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(vid, props.rules, {
    initialValue: props.modelValue,
    syncVModel: true
});

defineEmits(['update:modelValue', 'focusout', 'focus', 'enter'])
</script>

<script>
export default {
    name: "Field",
    watch: {
        modelValue() {
            this.content = this.modelValue;
        },
        content() {
            this.format();
        }
    },
    data() {
        return {
            content: null,
        }
    },
    mounted() {
        this.content = this.modelValue;
    },
    methods: {
        format() {
            let value = this.content;

            if (value != null && this.type === "number") {
                value = value.toString().replace(/\s/g, '').replace(/[^0-9]/g, '');
                if (this.typeFormat) {
                    this.content = value !== '' ? new Intl.NumberFormat('fr-FR').format(value) : '';
                }
            } else if (value != null && this.type === "siret") {
                value = value.toString().replace(/\s/g, '').replace(/[^0-9]/g, '');
            }

            if (!this.typeFormat) {
                this.content = value;
            }

            this.$nextTick(() => this.handleInput());
        },
        handleInput() {
            if (this.type === 'number') {
                this.$emit("update:modelValue", this.content != null ? this.content.replace(/\s/g, '') : null);
            } else {
                this.$emit("update:modelValue", this.content);
            }
        }
    },
    computed: {
        valueToDisplay() {
            if (this.content == null || this.content.length === 0) {
                return this.placeholder;
            }

            return this.content;
        }
    }
}
</script>

<style scoped lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
