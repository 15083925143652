<template>
    <NuxtLayout name="simulation">
        <template v-slot="slotProps">
            <c-form
                ref="form"
                @submit="nextStepSimu"
                @invalid-submit="showErrors = true"
                v-slot="{valid, errors, resetForm, validate}"
            >
                <div class="relative overflow-y-auto height-container pb-[7rem]">
                    <div class="absolute top-0 left-0 h-[2px] w-1/4 transition-all duration-700 ease-linear sm:hidden"
                        :class="{'!w-1/2': indexCurrentEtape === 2, '!w-3/4': indexCurrentEtape === 3, '!w-full': indexCurrentEtape === 4}"
                        :style="slotProps.colorStyles.bg.primary">
                    </div>

                    <div class="max-w-container sm:max-w-sm mx-auto md:max-w-md">
                        <div class="grid grid-cols-12 gap-6 m-auto max-w-container pt-16 sm:max-w-sm sm:pt-8 h-full">
                            <div class="col-span-8 sm:col-span-full">
                                <div v-if="indexCurrentEtape === 1" :key="1">
                                    <h1 class="text-overline uppercase mb-3"
                                        :class="{'text-grey7 font-semibold': slotProps.from === 'maslow', 'text-grey5 tracking-[1.4px]': slotProps.from === 'valority'}"
                                    >
                                        Votre profil
                                    </h1>

                                    <div class="text-2xl tracking-[.01em] mb-7" :class="{'font-berlingske': slotProps.from === 'maslow', 'font-playfair text-[#1F2945]': slotProps.from === 'valority'}">
                                        Avant de commencer, dites-nous
                                        <span :style="slotProps.from === 'valority' ? slotProps.colorStyles.text.primary : ''">qui vous êtes.</span>
                                    </div>

                                    <hr class="border-grey3 sm:hidden">

                                    <RecapEtapesMobile
                                        class="hidden sm:block"
                                        :color-styles="slotProps.colorStyles"
                                        :from="slotProps.from"
                                        :index-current-etape="indexCurrentEtape">
                                    </RecapEtapesMobile>

                                    <div class="mt-7 text-lg flex flex-col gap-2">
                                        <div class="flex flex-wrap gap-2 items-baseline">
                                            Vous avez
                                            <c-input-in-text-field
                                                vid="ageSimu"
                                                v-model="useSimulateurStore().results.infos.age"
                                                placeholder="âge"
                                                type="number"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :rules="{required: true, ageIsBetween: {targetMin: 18, targetMax: 100}}"
                                                @enter="() => { if (valid) {nextStepSimu(); return;} validate() }">
                                            </c-input-in-text-field>
                                            ans

                                            et vous habitez
                                            <c-input-in-text-select
                                                vid="etranger"
                                                v-model="simulation.results.infos.etranger"
                                                placeholder="en France"
                                                item-text="label"
                                                item-value="value"
                                                :items="[{label: 'en France', value: 'non'}, {label: 'à l\'étranger', value: 'oui'}]"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                rules="required"
                                                id="habitat">
                                            </c-input-in-text-select>

                                            <template v-if="simulation.results.infos.etranger === 'non'">
                                                dans la ville de
                                                <c-input-in-text-field
                                                    vid="commune"
                                                    v-model="simulation.results.infos.commune"
                                                    placeholder="ville"
                                                    type="text"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    rules="required"
                                                    id="commune"
                                                    @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                                ></c-input-in-text-field>
                                            </template>
                                        </div>

                                        <template v-if="simulation.results.infos.etranger === 'oui'">
                                            <div class="flex flex-wrap gap-2 items-baseline">
                                                Votre pays de résidence :
                                                <c-input-in-text-field
                                                    vid="pays"
                                                    v-model="simulation.results.infos.pays"
                                                    placeholder="Japon"
                                                    type="text"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    rules="required"
                                                    @enter="() => {if (valid){nextStepSimu(); return;}; validate()}">
                                                </c-input-in-text-field>
                                            </div>

                                            <div class="flex flex-wrap gap-2 items-baseline">
                                                Vous habitez dans la ville de
                                                <c-input-in-text-field
                                                    vid="communeEtranger"
                                                    v-model="simulation.results.infos.commune"
                                                    placeholder="Tokyo"
                                                    type="text"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    rules="required"
                                                    @enter="() => {if (valid){nextStepSimu(); return;}; validate()}">
                                                </c-input-in-text-field>
                                            </div>
                                        </template>

                                        <template v-if="simulation.results.infos.etranger != null && simulation.results.infos.commune != null">
                                            <div class="mt-6 flex flex-wrap gap-2 items-baseline">
                                                Vous souhaitez investir
                                                <c-input-in-text-select
                                                    vid="nbInvest"
                                                    v-model="simulation.results.infos.nbInvest"
                                                    placeholder="seul"
                                                    item-text="label"
                                                    item-value="value"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    :items="[{label: 'seul', value: 1}, {label: 'à deux', value: 2}]"
                                                    rules="required">
                                                </c-input-in-text-select>
                                            </div>
                                        </template>

                                        <div v-if="simulation.results.infos.nbInvest != null" class="flex flex-wrap gap-2 items-baseline mt-6">
                                            Votre projet d'achat concerne
                                            <c-input-in-text-select
                                                vid="objetSimu"
                                                v-model="simulation.results.infos.objet"
                                                placeholder="un investissement locatif"
                                                item-text="label"
                                                item-value="value"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :items="[{label: 'un investissement locatif', value: 'invest_loc'}, {label: 'une résidence principale', value: 'residence_princ'}]"
                                                rules="required"
                                            ></c-input-in-text-select>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="indexCurrentEtape === 2" :key="2">
                                    <h1 class="text-overline uppercase mb-3"
                                        :class="{'text-grey7 font-semibold': slotProps.from === 'maslow', 'text-grey5 tracking-[1.4px]': slotProps.from === 'valority'}"
                                    >
                                        Vos revenus
                                    </h1>

                                    <div class="text-2xl tracking-[.01em] mb-7" :class="{'font-berlingske': slotProps.from === 'maslow', 'font-playfair text-[#1F2945]': slotProps.from === 'valority'}">
                                        Maintenant, estimons
                                        <span :style="slotProps.from === 'valority' ? slotProps.colorStyles.text.primary : ''">vos revenus.</span>
                                    </div>

                                    <hr class="border-grey3 sm:hidden">

                                    <RecapEtapesMobile
                                        class="hidden sm:block"
                                        :color-styles="slotProps.colorStyles"
                                        :from="slotProps.from"
                                        :index-current-etape="indexCurrentEtape">
                                    </RecapEtapesMobile>

                                    <div class="mt-7 text-lg">
                                        <div class="mb-4 flex flex-wrap gap-2 items-baseline">
                                            Vous gagnez
                                            <c-input-in-text-field
                                                vid="revenuMontant"
                                                v-model="simulation.results.infos.investisseurs[0].revenuMontant"
                                                placeholder="45 000"
                                                type="number"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                rules="required"
                                                @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                            ></c-input-in-text-field>
                                            €

                                            <c-input-in-text-select
                                                vid="revenuType"
                                                v-model="simulation.results.infos.investisseurs[0].revenuType"
                                                placeholder="bruts"
                                                item-text="label"
                                                item-value="value"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :items="[{label: 'bruts', value: 'brut'}, {label: 'net', value: 'net'}]"
                                                rules="required"
                                            ></c-input-in-text-select>

                                            <c-input-in-text-select
                                                vid="revenuPeriode"
                                                v-model="simulation.results.infos.investisseurs[0].revenuPeriode"
                                                placeholder="par an"
                                                item-text="label"
                                                item-value="value"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :items="[{label: 'par an', value: 'an'}, {label: 'par mois', value: 'mois'}]"
                                                rules="required"
                                            ></c-input-in-text-select>
                                        </div>
                                    </div>

                                    <div class="mt-6 text-lg" v-if="simulation.results.infos.nbInvest === 2">
                                        <div class="mb-4 flex flex-wrap gap-2 items-baseline">
                                            Votre co-investisseur gagne
                                            <c-input-in-text-field
                                                vid="revenuMontantSecond"
                                                v-model="simulation.results.infos.investisseurs[1].revenuMontant"
                                                placeholder="45 000"
                                                type="number"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                rules="required"
                                                @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                            ></c-input-in-text-field>
                                            €

                                            <c-input-in-text-select
                                                vid="revenuTypeSecond"
                                                v-model="simulation.results.infos.investisseurs[1].revenuType"
                                                placeholder="bruts"
                                                item-text="label"
                                                item-value="value"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :items="[{label: 'bruts', value: 'brut'}, {label: 'net', value: 'net'}]"
                                                rules="required"
                                            ></c-input-in-text-select>

                                            <c-input-in-text-select
                                                vid="revenuPeriodeSecond"
                                                v-model="simulation.results.infos.investisseurs[1].revenuPeriode"
                                                placeholder="par an"
                                                item-text="label"
                                                item-value="value"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :items="[{label: 'par an', value: 'an'}, {label: 'par mois', value: 'mois'}]"
                                                rules="required"
                                            ></c-input-in-text-select>
                                        </div>
                                    </div>

                                    <div class="mt-12 mb-4 text-lg">
                                        Vous
                                        <c-input-in-text-select
                                            vid="revenuAutre"
                                            v-model="simulation.results.infos.investisseurs[0].revenuAutre"
                                            placeholder="avez"
                                            item-text="label"
                                            item-value="value"
                                            :from="slotProps.from"
                                            :color-override="slotProps.colorStyles"
                                            :items="[{label: 'avez', value: 'avez'}, {label: 'n\'avez pas', value: 'avez_pas'}]"
                                            rules="required"
                                        ></c-input-in-text-select>
                                        {{ simulation.results.infos.nbInvest === 2 ? "l'un ou l'autre " : "" }}d'autres sources de revenus.
                                    </div>

                                    <div v-if="simulation.results.infos.investisseurs[0].revenuAutre === 'avez'" class="pb-16">
                                        <div v-for="(autreRevenu, index) in simulation.results.infos.investisseurs[0].revenuAutreItems"
                                             class="flex flex-col items-end mb-4 gap-2 flex-wrap">
                                            <div class="border rounded p-4 w-full text-lg"
                                                 :class="{'border-red': hasError('revenu', index)}">
                                                Il s'agit
                                                <c-input-in-text-select
                                                    :vid="'revenu_autre_' + index + '_type'"
                                                    v-model="autreRevenu.type"
                                                    placeholder="d'un revenu locatif"
                                                    item-text="label"
                                                    item-value="value"
                                                    class="mb-2"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    :items="[{label: 'd\'un revenu locatif', value: 'revenu_locatif'}, {label: 'de dividendes', value: 'dividendes'}, {label: 'de rentes et pensions', value: 'rentes_pensions'}, {label: 'd\'un autre type de revenu', value: 'autre'}]"
                                                    rules="required"
                                                ></c-input-in-text-select>

                                                d'un montant de
                                                <c-input-in-text-field
                                                    :vid="'revenu_autre_' + index + '_montant'"
                                                    v-model="autreRevenu.montant"
                                                    placeholder="45 000"
                                                    type="number"
                                                    class="mb-2"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    :rules="{required: true, numberIsUpperOrEqual: {targetValue: 1}}"
                                                    @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                                ></c-input-in-text-field>
                                                €

                                                <c-input-in-text-select
                                                    :vid="'revenu_autre_' + index + '_periode'"
                                                    v-model="autreRevenu.periode"
                                                    placeholder="par an"
                                                    item-text="label"
                                                    item-value="value"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    :items="[{label: 'par an', value: 'an'}, {label: 'par mois', value: 'mois'}]"
                                                    rules="required"
                                                ></c-input-in-text-select>
                                            </div>

                                            <div @click.prevent="removeItem('mainInvestRevenu', index)"
                                                 class="flex items-center gap-2 text-gray-400 cursor-pointer">
                                                <icons-trash-circle-outline class="w-6"></icons-trash-circle-outline>
                                                Supprimer la ligne
                                            </div>
                                        </div>

                                        <div @click.prevent="addItem('mainInvestRevenu')"
                                             class="text-gray-400 flex cursor-pointer w-fit">
                                            <icons-plus-circle-outline class="w-6 inline-block mr-3"></icons-plus-circle-outline>
                                            Ajouter une autre source de revenus
                                        </div>
                                    </div>
                                </div>

                                <div v-if="indexCurrentEtape === 3" :key="3">
                                    <h1 class="text-overline uppercase mb-3"
                                        :class="{'text-grey7 font-semibold': slotProps.from === 'maslow', 'text-grey5 tracking-[1.4px]': slotProps.from === 'valority'}"
                                    >
                                        Vos charges
                                    </h1>

                                    <div class="text-2xl tracking-[.01em] mb-7" :class="{'font-berlingske': slotProps.from === 'maslow', 'font-playfair text-[#1F2945]': slotProps.from === 'valority'}">
                                        Maintenant, estimons
                                        <span :style="slotProps.from === 'valority' ? slotProps.colorStyles.text.primary : ''">vos charges.</span>
                                    </div>

                                    <hr class="border-grey3 sm:hidden">

                                    <RecapEtapesMobile
                                        class="hidden sm:block"
                                        :color-styles="slotProps.colorStyles"
                                        :from="slotProps.from"
                                        :index-current-etape="indexCurrentEtape">
                                    </RecapEtapesMobile>

                                    <div class="mt-7 text-lg">
                                        <template v-if="simulation.results.infos.nbInvest === 2">
                                            <div class="mb-4 flex flex-wrap gap-2 items-baseline" >
                                                Votre co-investisseur et vous habitez
                                                <c-input-in-text-select
                                                    vid="viveEnsemble"
                                                    v-model="simulation.results.infos.viveEnsemble"
                                                    placeholder="ensemble"
                                                    item-text="label"
                                                    item-value="value"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    :items="[{label: 'ensemble', value: 'ensemble'}, {label: 'séparément', value: 'separement'}]"
                                                    rules="required">
                                                </c-input-in-text-select>
                                            </div>
                                        </template>

                                        <div class="mb-4 flex flex-wrap gap-2 items-baseline">
                                            Pour vous loger, vous payez
                                            <c-input-in-text-select
                                                vid="locOuProp"
                                                v-model="simulation.results.infos.investisseurs[0].locOuProp"
                                                placeholder="un crédit immobilier"
                                                item-text="label"
                                                item-value="value"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :items="[{label: 'un crédit immobilier', value: 'proprietaire'}, {label: 'un loyer', value: 'locataire'}, {label: 'aucun des deux', value: 'heberge_gratuit'}]"
                                                rules="required"
                                            ></c-input-in-text-select>
                                        </div>

                                        <div v-if="simulation.results.infos.investisseurs[0].locOuProp === 'locataire'"
                                             class="mb-4 border rounded p-4">
                                            Votre loyer est de
                                            <c-input-in-text-field
                                                vid="mensualitesLoyer"
                                                v-model="simulation.results.infos.investisseurs[0].mensualitesLoyer"
                                                placeholder="700"
                                                type="number"
                                                rules="required"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                            ></c-input-in-text-field>
                                            € charges comprises par mois.
                                        </div>

                                        <div v-if="simulation.results.infos.investisseurs[0].hasPretImmobilier === 'avez'"
                                             class="mb-4 border rounded p-4 flex sm:flex-col gap-2 sm:p-2">
                                            <div class="flex flex-wrap gap-2 items-baseline">
                                                Sa mensualité est de
                                                <c-input-in-text-field
                                                    vid="mensualitePretImmobilier"
                                                    v-model="simulation.results.infos.investisseurs[0].mensualitesPretImmobilier"
                                                    placeholder="700"
                                                    type="number"
                                                    class="mb-2"
                                                    rules="required"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                                ></c-input-in-text-field>
                                                €.
                                            </div>

                                            <div class="flex flex-wrap gap-2 items-baseline">
                                                Il se terminera
                                                <c-input-in-text-select
                                                    vid="dateFinPretImmobilier"
                                                    v-model="simulation.results.infos.investisseurs[0].dateFinPretImmobilier"
                                                    placeholder="dans moins de 2 ans"
                                                    item-text="label"
                                                    item-value="value"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    :items="[{label: 'dans moins de 2 ans', value: 'moins_deux_ans'}, {label: 'entre 2 et 10 ans', value: 'deux_et_dix'}, {label: 'dans plus de 10 ans', value: 'plus_dix_ans'}]"
                                                    rules="required">
                                                </c-input-in-text-select>
                                            </div>
                                        </div>

                                        <div v-if="simulation.results.infos.nbInvest === 2 && simulation.results.infos.viveEnsemble === 'separement'">
                                            <div class="mb-4 flex flex-wrap gap-2 items-baseline">
                                                Votre co-investisseur paie
                                                <c-input-in-text-select
                                                    vid="locOuPropSecond"
                                                    v-model="simulation.results.infos.investisseurs[1].locOuProp"
                                                    placeholder="un crédit immobilier"
                                                    item-text="label"
                                                    item-value="value"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    :items="[{label: 'un crédit immobilier', value: 'proprietaire'}, {label: 'un loyer', value: 'locataire'}, {label: 'aucun des deux', value: 'heberge_gratuit'}]"
                                                    rules="required"
                                                ></c-input-in-text-select>
                                            </div>

                                            <div v-if="simulation.results.infos.investisseurs[1].locOuProp === 'locataire'"
                                                 class="mb-4 border rounded p-4">
                                                Son loyer est de
                                                <c-input-in-text-field
                                                    vid="mensualitesLoyerSecond"
                                                    v-model="simulation.results.infos.investisseurs[1].mensualitesLoyer"
                                                    placeholder="700"
                                                    type="number"
                                                    rules="required"
                                                    :from="slotProps.from"
                                                    :color-override="slotProps.colorStyles"
                                                    @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                                ></c-input-in-text-field>
                                                € charges comprises par mois.
                                            </div>

                                            <div v-if="simulation.results.infos.investisseurs[1].hasPretImmobilier === 'avez'"
                                                 class="mb-4 flex gap-2 sm:flex-col border rounded p-4 sm:p-2">
                                                <div>
                                                    Sa mensualité est de
                                                    <c-input-in-text-field
                                                        vid="mensualitePretImmobilierSecond"
                                                        v-model="simulation.results.infos.investisseurs[1].mensualitesPretImmobilier"
                                                        placeholder="700"
                                                        type="number"
                                                        rules="required"
                                                        :from="slotProps.from"
                                                        :color-override="slotProps.colorStyles"
                                                        @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                                    ></c-input-in-text-field>
                                                    €.
                                                </div>

                                                <div>
                                                    Il se terminera
                                                    <c-input-in-text-select
                                                        vid="dateFinPretImmobilierSecond"
                                                        v-model="simulation.results.infos.investisseurs[1].dateFinPretImmobilier"
                                                        placeholder="dans moins de 2 ans"
                                                        item-text="label"
                                                        item-value="value"
                                                        :from="slotProps.from"
                                                        :color-override="slotProps.colorStyles"
                                                        :items="[{label: 'dans moins de 2 ans', value: 'moins_deux_ans'}, {label: 'entre 2 et 10 ans', value: 'deux_et_dix'}, {label: 'dans plus de 10 ans', value: 'plus_dix_ans'}]"
                                                        rules="required">
                                                    </c-input-in-text-select>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-4 mt-12">
                                            Vous {{ simulation.results.infos.nbInvest === 2 ? 'et votre co-investisseur' : '' }}
                                            <c-input-in-text-select
                                                vid="autresCharges"
                                                v-model="simulation.results.infos.investisseurs[0].autresCharges"
                                                placeholder="n'avez pas"
                                                item-text="label"
                                                item-value="value"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :items="[{label: 'avez', value: 'avez'}, {label: 'n\'avez pas', value: 'avez_pas'}]"
                                                rules="required"
                                            ></c-input-in-text-select>
                                            d’autre(s) crédit(s) en cours (immo, conso, auto...).
                                        </div>
                                    </div>

                                    <div>
                                        <div v-if="simulation.results.infos.investisseurs[0].autresCharges === 'avez'">
                                            <div v-if="!loading"
                                                 v-for="(autreCharge, index) in simulation.results.infos.investisseurs[0].autresChargesItems"
                                                 class="mt-6 mb-4 flex flex-col items-end gap-2">
                                                <div class="flex flex-col border border-gray-200 rounded p-4 w-full text-lg"
                                                     :class="{'border-red': hasError('charge', index)}">
                                                    <div class="mb-4 flex flex-wrap gap-2 items-baseline">
                                                        Il s'agit d'un
                                                        <c-input-in-text-select
                                                            :vid="'charge_autre_' + index + '_type'"
                                                            v-model="autreCharge.type"
                                                            placeholder="prêt immobilier"
                                                            item-text="label"
                                                            item-value="value"
                                                            :from="slotProps.from"
                                                            :color-override="slotProps.colorStyles"
                                                            :items="[{label: 'prêt immobilier', value: 'pret_immobilier'}, {label: 'crédit auto/moto', value: 'credit_auto'}, {label: 'crédit consommation', value: 'credit_consommation'}]"
                                                            rules="required"
                                                        ></c-input-in-text-select>

                                                        d'une mensualité de
                                                        <c-input-in-text-field
                                                            :vid="'charge_autre_' + index + '_mensualite'"
                                                            v-model="autreCharge.mensualite"
                                                            placeholder="500"
                                                            type="number"
                                                            rules="required"
                                                            :from="slotProps.from"
                                                            :color-override="slotProps.colorStyles"
                                                            @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                                        ></c-input-in-text-field>
                                                        €.
                                                    </div>

                                                    <div>
                                                        Il se terminera
                                                        <c-input-in-text-select
                                                            :vid="'charge_autre_' + index + '_date_fin'"
                                                            v-model="autreCharge.dateFin"
                                                            placeholder="dans moins de 2 ans"
                                                            item-text="label"
                                                            item-value="value"
                                                            :from="slotProps.from"
                                                            :color-override="slotProps.colorStyles"
                                                            :items="[{label: 'dans moins de 2 ans', value: 'moins_deux_ans'}, {label: 'entre 2 et 10 ans', value: 'deux_et_dix'}, {label: 'dans plus de 10 ans', value: 'plus_dix_ans'}]"
                                                            rules="required">
                                                        </c-input-in-text-select>
                                                    </div>
                                                </div>

                                                <div @click.prevent="removeItem('mainInvestCharge', index)"
                                                     class="flex items-center gap-2 text-gray-400 cursor-pointer">
                                                    <icons-trash-circle-outline class="w-6"></icons-trash-circle-outline>
                                                    Supprimer la ligne
                                                </div>
                                            </div>

                                            <div @click.prevent="addItem('mainInvestCharge')"
                                                 class="text-gray-400 flex cursor-pointer w-fit">
                                                <icons-plus-circle-outline class="w-6 inline-block mr-3"></icons-plus-circle-outline>
                                                Ajouter un autre crédit
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="indexCurrentEtape === 4" :key="4">
                                    <h1 class="text-overline uppercase mb-3"
                                        :class="{'text-grey7 font-semibold': slotProps.from === 'maslow', 'text-grey5 tracking-[1.4px]': slotProps.from === 'valority'}"
                                    >
                                        Votre situation fiscale
                                    </h1>

                                    <div class="text-2xl tracking-[.01em] mb-7" :class="{'font-berlingske': slotProps.from === 'maslow', 'font-playfair text-[#1F2945]': slotProps.from === 'valority'}">
                                        Aidez-nous à connaître
                                        <span :style="slotProps.from === 'valority' ? slotProps.colorStyles.text.primary : ''">votre situation fiscale.</span>
                                    </div>

                                    <hr class="border-grey3 sm:hidden">

                                    <RecapEtapesMobile
                                        class="hidden sm:block"
                                        :color-styles="slotProps.colorStyles"
                                        :from="slotProps.from"
                                        :index-current-etape="indexCurrentEtape">
                                    </RecapEtapesMobile>

                                    <div class="mt-7 text-lg">
                                        <div v-if="simulation.results.infos.nbInvest === 2" class="mb-4" >
                                            Avec votre co-investisseur, vous payez vos impôts
                                            <c-input-in-text-select
                                                vid="impotEnsemble"
                                                v-model="simulation.results.infos.impotsEnsemble"
                                                placeholder="ensemble"
                                                class="mb-2"
                                                item-text="label"
                                                item-value="value"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                :items="[{label: 'ensemble', value: 'ensemble'}, {label: 'séparément', value: 'separement'}]"
                                                rules="required"
                                            ></c-input-in-text-select>
                                        </div>

                                        <div>
                                            Vous payez
                                            <c-input-in-text-field
                                                vid="impotsMontant"
                                                v-model="simulation.results.infos.investisseurs[0].impotsMontant"
                                                placeholder="3 000"
                                                type="number"
                                                rules="required"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                            ></c-input-in-text-field>
                                            € d’impôts par an.
                                        </div>

                                        <div class="underline cursor-pointer"
                                             :style="slotProps.colorStyles.text.primary"
                                             @click="showModalImpot = !showModalImpot">
                                            Où trouver cette information ?
                                        </div>

                                        <div class="my-4 flex flex-wrap gap-2 items-baseline"
                                             v-if="simulation.results.infos.nbInvest === 2 && simulation.results.infos.impotsEnsemble === 'separement'">
                                            Votre co-investisseur paie
                                            <c-input-in-text-field
                                                vid="impotsMontantSecond"
                                                v-model="simulation.results.infos.investisseurs[1].impotsMontant"
                                                placeholder="3 000"
                                                type="number"
                                                rules="required"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                            ></c-input-in-text-field>
                                            € d’impôts par an.
                                        </div>

                                        <div class="mt-4" :class="{'!mt-12': simulation.results.infos.nbInvest === 2}">
                                            Vous avez un apport de
                                            <c-input-in-text-field
                                                vid="apportMontant"
                                                v-model="simulation.results.infos.investisseurs[0].apportMontant"
                                                placeholder="10 000"
                                                type="number"
                                                rules="required"
                                                :from="slotProps.from"
                                                :color-override="slotProps.colorStyles"
                                                @enter="() => {if (valid){nextStepSimu(); return;}; validate()}"
                                            ></c-input-in-text-field>
                                            €
                                            {{ simulation.results.infos.nbInvest === 2 ? 'à deux ' : '' }}pour financer votre projet.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-start-9 col-span-4 sm:hidden flex flex-col justify-between h-full pb-6 gap-y-3">
                                <RecapEtapes
                                    :color-styles="slotProps.colorStyles"
                                    :from="slotProps.from"
                                    :index-current-etape="indexCurrentEtape">
                                </RecapEtapes>

                                <div v-if="Object.values(errors)[0]"
                                     class="border-2 border-red bg-red-dimmed text-red rounded px-4 py-3 flex gap-2">
                                    <icons-exclamation-outline class="w-6"></icons-exclamation-outline>
                                    {{ Object.values(errors)[0] === "Champ requis" ? "Merci de renseigner tous les champs." : Object.values(errors)[0] }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <FooterSimulation
                    :index-current-etape="indexCurrentEtape"
                    :disable-next-button="disableNextButton"
                    :color-styles="slotProps.colorStyles"
                    :from="slotProps.from"
                    @prev="prevStepSimu"
                    @clear="reset(resetForm)"
                    class="!fixed bottom-0">
                </FooterSimulation>
            </c-form>

            <ImpotsModal
                :show="showModalImpot"
                :color-styles="slotProps.colorStyles"
                :from="slotProps.from"
                @close="showModalImpot = false">
            </ImpotsModal>

            <ContactModal
                v-model:mobile="simulation.results.mobile"
                v-model:nom="simulation.results.nom"
                v-model:prenom="simulation.results.prenom"
                v-model:email="simulation.results.email"
                v-model:code-postal="simulation.results.codePostal"
                v-model:show="showModalContact"
                @resultat="resultatSimu"
                @close="showModalContact = false">
            </ContactModal>

            <ContactModalValority
                v-model:contact-valority="contactValority"
                v-model:show="showModalContactValority"
                :color-styles="slotProps.colorStyles"
                @resultat="resultatSimu"
                @close="showModalContactValority = false">
            </ContactModalValority>

            <Gamned
                v-if="from === 'maslow'"
                :index-current-etape="indexCurrentEtape">
            </Gamned>
        </template>
    </NuxtLayout>
</template>

<script>
import {useUserStore} from "~/store/user";
import {useSimulateurStore} from "~/store/simulateur";

import RecapEtapesMobile from "@/pages/simulation/components/RecapEtapesMobile";
import RecapEtapes from "@/pages/simulation/components/RecapEtapes";
import Gamned from "~/pages/simulation/components/gamned";
import ImpotsModal from "~/pages/simulation/components/ImpotsModal";
import ContactModal from "~/pages/simulation/components/ContactModal";
import ContactModalValority from "~/pages/simulation/components/ContactModalValority";

export default {
    name: "index",
    components: {
        RecapEtapesMobile,
        RecapEtapes,
        Gamned,
        ImpotsModal,
        ContactModal,
        ContactModalValority
    },
    setup() {
        let from = "maslow";
        let favicon = [];
        let script = [];

        if (useRequestHeaders().host != null) {
            from = useRequestHeaders().host.includes('capacite-emprunt') ? 'valority' : 'maslow';
        } else if (window != null) {
            from = window.location.host.includes('capacite-emprunt') ? 'valority' : 'maslow';
        }

        if (from === 'valority') {
            favicon = [{rel: 'icon', type: 'image/png', href: '/favicon-valority-32x32.png', hid: 'favicon'}];
            script = [{hid: 'nuxtConsent'}, {hid: 'cookiebot'}, {hid: 'gtm'}]
        }

        const head = computed(() => {
            return {
                title: 'Simulation capacité d\'investissement | ' + capitalizeFirstLetter(from ?? '...'),
                meta: [
                    {
                        type: 'description',
                        content: 'Calculez gratuitement et facilement votre capacité d\'investissement dans l\'immobilier locatif.'
                    }
                ],
                link: favicon,
                script: script
            }
        });

        useHead(head);

        definePageMeta({
            layout: false
        });

        onBeforeRouteLeave((to, from, next) => {
            if (to.name !== 'simulation-resultats') {
                useSimulateurStore().$reset();
            }

            next();
        });

        const simulation = ref(useSimulateurStore());

        return {from, simulation};
    },
    data() {
        return {
            indexCurrentEtape: 1,
            loading: false,

            contactValority: {
                nom: null,
                prenom: null,
                email: null,
                telephone: null,
                ville: null,
                codePostal: null
            },
            showModalContact: false,
            showModalContactValority: false,
            showErrors: false,
            showModalImpot: false,
            userStore: useUserStore(),
            disableNextButton: false
        }
    },
    mounted() {
        if (useRoute().query.fromResult) {
            useSimulateurStore().isEdit = true;
        }

        this.emitListener(this.$refs.form.reset);
    },
    unmounted() {
        useNuxtApp().$off('clear-simu');
    },
    methods: {
        useSimulateurStore,
        emitListener(resetForm) {
            useNuxtApp().$on('clear-simu', () => {
                useSimulateurStore().$reset();

                this.reset(resetForm);
            })
        },
        hasError(type, i) {
            let providers = [];

            if (type === 'revenu') {
                providers = ['revenu_autre_' + i + '_type', 'revenu_autre_' + i + '_montant', 'revenu_autre_' + i + '_periode'];
            }

            if (type === 'charge') {
                providers = ['charge_autre_' + i + '_type', 'charge_autre_' + i + '_mensualite', 'charge_autre_' + i + '_date_fin'];
            }

            return providers.some((p) => this.$refs.form && this.$refs.form.getValues().hasOwnProperty(p) && this.$refs.form.getErrors().hasOwnProperty(p), this);
        },
        removeItem(type, index) {
            this.loading = true;

            if (type === 'mainInvestRevenu') {
                this.simulation.results.infos.investisseurs[0].revenuAutreItems.splice(index, 1);

                if (this.simulation.results.infos.investisseurs[0].revenuAutreItems.length === 0) {
                    this.simulation.results.infos.investisseurs[0].revenuAutre = 'avez_pas';
                }
            } else if (type === 'mainInvestCharge') {
                this.simulation.results.infos.investisseurs[0].autresChargesItems.splice(index, 1);

                if (this.simulation.results.infos.investisseurs[0].autresChargesItems.length === 0) {
                    this.simulation.results.infos.investisseurs[0].autresCharges = 'avez_pas';
                }
            }

            this.$nextTick(() => {
                this.loading = false;
            });
        },
        isObjectFilled(obj) {
            for (let i = 0; i < obj.length; i++) {
                obj[i]['error'] = false;
                if (Object.values(obj[i]).some(x => x === null || x === '' || x === "0")) {
                    obj[i]['error'] = true;
                    return false;
                }
            }

            return true;
        },
        addItem(type) {
            if (type === 'mainInvestRevenu') {
                if (this.isObjectFilled(this.simulation.results.infos.investisseurs[0].revenuAutreItems)) {
                    this.simulation.results.infos.investisseurs[0].revenuAutreItems.push({
                        'type': null,
                        'montant': null,
                        'periode': null,
                        'error': null
                    });
                } else {
                    for (let i = 0; i < this.simulation.results.infos.investisseurs[0].revenuAutreItems.length; i++) {
                        if (this.simulation.results.infos.investisseurs[0].revenuAutreItems[i].error) {
                            let providers = ['revenu_autre_' + i + '_type', 'revenu_autre_' + i + '_montant', 'revenu_autre_' + i + '_periode'];

                            providers.forEach((p) => this.$refs.form.validateField(p), this);
                        }
                    }
                }
            } else if (type === 'mainInvestCharge') {
                if (this.isObjectFilled(this.simulation.results.infos.investisseurs[0].autresChargesItems)) {
                    this.simulation.results.infos.investisseurs[0].autresChargesItems.push({
                        'type': null,
                        'mensualite': null,
                        'dateFin': null,
                        'error': null
                    });
                } else {
                    for (let i = 0; i < this.simulation.results.infos.investisseurs[0].autresChargesItems.length; i++) {
                        if (this.simulation.results.infos.investisseurs[0].autresChargesItems[i].error) {
                            let providers = ['charge_autre_' + i + '_type', 'charge_autre_' + i + '_mensualite', 'charge_autre_' + i + '_date_fin'];

                            providers.forEach((p) => this.$refs.form.validateField(p), this);
                        }
                    }
                }
            }
        },
        prevStepSimu() {
            if (this.indexCurrentEtape >= 2 && this.indexCurrentEtape <= 4) {
                this.indexCurrentEtape--;
                this.$nextTick(() => document.getElementById('input').focus());
            } else if (this.indexCurrentEtape === 1) {
                if (this.from === 'maslow') {
                    useRouter().push({name: 'index'});
                } else if (this.from === 'valority') {
                    window.location.href = 'https://www.valority.com/';
                }
            }
        },
        nextStepSimu() {
            this.showErrors = false;

            if (this.indexCurrentEtape >= 1 && this.indexCurrentEtape < 4) {
                this.indexCurrentEtape++;

                this.$nextTick(() => document.getElementById('input').focus());
            } else if (this.indexCurrentEtape === 4) {
                this.resultatSimu();
            }
        },
        resultatSimu() {
            let redirect = true;

            if (this.from === 'maslow') {
                if (!this.userStore.isLoggedIn) {
                    if (this.simulation.results.mobile !== null && this.simulation.results.mobile !== undefined
                        && this.simulation.results.nom !== null && this.simulation.results.nom !== '' && this.simulation.results.nom !== undefined
                        && this.simulation.results.prenom !== null && this.simulation.results.prenom !== '' && this.simulation.results.prenom !== undefined
                    ) {
                        useSimulateurStore().results.mobile = this.simulation.results.mobile;
                        useSimulateurStore().results.nom = this.simulation.results.nom;
                        useSimulateurStore().results.prenom = this.simulation.results.prenom;
                        useSimulateurStore().results.email = this.simulation.results.email;

                        this.showModalContact = false;
                    } else {
                        this.showModalContact = true;
                        redirect = false;
                    }
                }
            } else if (this.from === 'valority') {
                if (this.contactValority.telephone !== null && this.contactValority.telephone !== undefined) {
                    useSimulateurStore().results.contactValority = this.contactValority;
                    this.showModalContactValority = false;
                } else {
                    this.showModalContactValority = true;
                    redirect = false;
                }
            }

            if (redirect === true) {
                useSimulateurStore().init();
                useSimulateurStore().calcul();

                this.disableNextButton = true;

                if (this.from === 'maslow') {
                    if (useSimulateurStore().isEdit === false || useUserStore().isLoggedIn === false) {
                        useSimulateurStore().save();
                    }
                } else if (this.from === 'valority') {
                    useSimulateurStore().saveValority();
                }

                useRouter().push({name: 'simulation-resultats'});
            }
        },
        reset(resetForm) {
            useSimulateurStore().$reset();
            resetForm({
                values: {
                    ageSimu: null,
                    etranger: null,
                }
            });

            this.indexCurrentEtape = 1;
        },
    },
    watch: {
        'simulation.results.infos': {
            handler() {
                setLocalStorageValue('simulation', JSON.stringify(this.simulation.results.infos));
            },
            deep: true
        },
        'simulation.results.infos.etranger'() {
            if (this.simulation.results.infos.etranger === 'non') {
                this.simulation.results.infos.pays = 'France';
            } else if (this.simulation.results.infos.etranger === null) {
                this.simulation.results.infos.commune = null;
            } else if (this.simulation.results.infos.etranger === 'oui' && this.simulation.results.infos.pays === 'France') {
                this.simulation.results.infos.pays = null;
            }
        },
        'simulation.results.infos.nbInvest'() {
            if (this.simulation.results.infos.nbInvest === 2 && this.simulation.results.infos.investisseurs.length === 1) {
                useSimulateurStore().addInvestor();
            } else if (this.simulation.results.infos.nbInvest === 1 && this.simulation.results.infos.investisseurs.length > 1) {
                useSimulateurStore().deleteInvestor();
                this.simulation.results.infos.viveEnsemble = null;
                this.simulation.results.infos.impotsEnsemble = null;
            }
        },
        'simulation.results.infos.investisseurs.0.revenuAutre'() {
            if (this.simulation.results.infos.investisseurs[0].revenuAutre === "avez" && this.simulation.results.infos.investisseurs[0].revenuAutreItems?.length === 0) {
                this.addItem('mainInvestRevenu');
            } else if (this.simulation.results.infos.investisseurs[0].revenuAutre === "avez_pas" || this.simulation.results.infos.investisseurs[0].revenuAutre === null) {
                this.simulation.results.infos.investisseurs[0].revenuAutreItems = [];
            }
        },
        'simulation.results.infos.investisseurs.0.autresCharges'() {
            if (this.simulation.results.infos.investisseurs[0].autresCharges === 'avez_pas' || this.simulation.results.infos.investisseurs[0].autresCharges === null) {
                this.simulation.results.infos.investisseurs[0].autresChargesItems = [];
            } else {
                if (this.simulation.results.infos.investisseurs[0].autresChargesItems.length === 0) {
                    this.addItem('mainInvestCharge');
                }
            }
        },
        'simulation.results.infos.investisseurs': {
            handler() {
                for (let i = 0; i < this.simulation.results.infos.investisseurs.length; i++) {
                    if (this.simulation.results.infos.investisseurs[i].locOuProp !== "locataire") {
                        this.simulation.results.infos.investisseurs[i].mensualitesLoyer = null;
                    }

                    if (this.simulation.results.infos.investisseurs[i].locOuProp === "proprietaire") {
                        this.simulation.results.infos.investisseurs[i].hasPretImmobilier = 'avez';
                    } else {
                        this.simulation.results.infos.investisseurs[i].hasPretImmobilier = 'avez_pas';
                    }

                    if (this.simulation.results.infos.investisseurs[i].hasPretImmobilier === 'avez_pas') {
                        this.simulation.results.infos.investisseurs[i].mensualitesPretImmobilier = null;
                        this.simulation.results.infos.investisseurs[i].dateFinPretImmobilier = null;
                    }
                }
            }, deep: true
        },
        'simulation.results.infos.viveEnsemble'() {
            if (this.simulation.results.infos.viveEnsemble !== "separement" && this.simulation.results.infos.nbInvest === 2) {
                this.simulation.results.infos.investisseurs[1].locOuProp = null;
            }
        },
        'simulation.results.infos.impotsEnsemble'() {
            if (this.simulation.results.infos.impotsEnsemble !== "separement" && this.simulation.results.infos.nbInvest === 2) {
                this.simulation.results.infos.investisseurs[1].impotsMontant = null;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.height-container {
    height: calc(100vh - 7rem - theme('height.menu'));
}
</style>
