<template>
    <c-modal
        v-model="showModal"
        ref="modal"
        @close="$emit('update:show', false)"
        :width="isMobile ? '100%' : '40%'"
    >
        <div class="modal bg-white py-10 sm:p-6 sm:overflow-y-scroll shadow-2xl rounded-2xl"
             :class="{'height-mobile': isMobile}"
        >
            <div class="w-2/3 sm:w-full mx-auto flex flex-col justify-center items-center m-auto sm:max-w-sm sm:overflow-x-scroll">
                <h1 class="text-xl text-center mb-2" :style="colorStyles.text.action">
                    <span class="font-light">Merci de renseigner vos coordonnées</span><br>
                    <span class="font-bold">pour recevoir votre résultat</span>
                </h1>

                <div class="flex w-full items-center gap-3 sm:gap-1 my-5" style="font-size: 10px;">
                    <div class="border-[0.5px] w-full h-[0.5px] sm:hidden" :style="colorStyles.border.action"></div>

                    <div class="flex gap-1">
                        <icons-lock-closed-outline class="w-4 sm:w-3"></icons-lock-closed-outline>

                        <div class="whitespace-nowrap tracking-[0.5px]" :style="colorStyles.text.action">
                            VOS DONNÉES SONT CONFIDENTIELLES & SÉCURISÉES
                        </div>
                    </div>

                    <div class="border-[0.5px] w-full h-[0.5px] sm:hidden" :style="colorStyles.border.action"></div>
                </div>

                <c-form @submit="save">
                    <div class="flex flex-col items-center gap-5">
                        <div class="flex gap-3 w-full">
                            <c-text-field
                                label="Votre nom*"
                                vid="nom"
                                v-model="contactValorityTemp.nom"
                                rules="required"
                                autocomplete="family-name"
                                class="!w-1/2"
                                label-color="#1F2945"
                                from="valority"
                                show-validation-errors>
                            </c-text-field>

                            <c-text-field
                                label="Votre prénom*"
                                vid="prenom"
                                v-model="contactValorityTemp.prenom"
                                rules="required"
                                autocomplete="given-name"
                                class="!w-1/2"
                                label-color="#1F2945"
                                from="valority"
                                show-validation-errors>
                            </c-text-field>
                        </div>

                        <div class="flex gap-3 w-full">
                            <c-text-field
                                label="Votre email*"
                                vid="email"
                                v-model="contactValorityTemp.email"
                                rules="required|email"
                                type="email"
                                inputmode="email"
                                autocomplete="email"
                                class="!w-1/2"
                                label-color="#1F2945"
                                from="valority"
                                show-validation-errors>
                            </c-text-field>

                            <c-input-telephone
                                vid="telephonePortable"
                                required
                                v-model="mobileTemp"
                                show-validation-errors
                                return-value="number"
                                class="!w-1/2"
                                label-color="#1F2945"
                                from="valority"
                                label="Votre téléphone*"
                            ></c-input-telephone>
                        </div>

                        <div class="flex gap-3 w-full">
                            <c-text-field
                                label="Votre code postal*"
                                vid="cp"
                                v-model="contactValorityTemp.codePostal"
                                rules="required"
                                label-color="#1F2945"
                                from="valority"
                                show-validation-errors>
                            </c-text-field>

                            <c-text-field
                                label="Votre ville*"
                                vid="ville"
                                v-model="contactValorityTemp.ville"
                                rules="required"
                                label-color="#1F2945"
                                from="valority"
                                show-validation-errors>
                            </c-text-field>
                        </div>

                        <c-button
                            :loading="useSimulateurStore().loading"
                            :disabled="useSimulateurStore().loading"
                            class="btn-valority text-white flex items-center gap-2 mt-2"
                            :style="colorStyles.bg.action"
                        >
                            Voir mon résultat
                            <icons-arrow-right-solid class="w-5"></icons-arrow-right-solid>
                        </c-button>
                    </div>
                </c-form>

                <div class="mt-8 text-grey6 text-xs leading-[18px]">
                    *Les informations collectées font l’objet d’un traitement automatisé et informatisé destiné
                    notamment à la mise en œuvre des services proposés et à des fins de prospection commerciale par
                    Valority.<br>
                    Pour toute information complémentaire relative au traitement de vos données personnelles,
                    <a href="https://valority.com/app/uploads/2023/01/politique-de-protection-des-donnees-personnelles.pdf" target="_blank" class="underline">
                        consulter ici notre politique de protection des données personnelles
                    </a>.
                    Conformément à la loi du 6 janvier 1978 et au Règlement Général sur la Protection des Données (RGPD)
                    du 27 avril 2016 N°2016/679, vous disposez d’un droit d’accès, de rectification, d’opposition et
                    d’effacement à formuler auprès du responsable de traitement, à l’adresse suivante :
                    rgpd@valority.com
                </div>
            </div>
        </div>
    </c-modal>
</template>

<script>
import {useSimulateurStore} from "~/store/simulateur";

export default {
    name: "ContactModalValority",
    emits: ['update:contactValority', 'update:show', 'resultat'],
    props: {
        contactValority: {
            type: Object
        },
        show: {
            type: Boolean,
        },
        closable: {
            type: Boolean,
            default: false
        },
        colorStyles: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            contactValorityTemp: this.contactValority,
            mobileTemp: null,
            formattedNumber: null,
            phoneNumberInvalid: false,
            showModal: null
        }
    },
    watch: {
        show() {
            this.showModal = this.show;
        },
        mobileTemp() {
            if (this.mobileTemp !== null) {
                this.formattedNumber = this.mobileTemp.replace('+', '');
                this.contactValorityTemp.telephone = this.formattedNumber;
            }
        },
    },
    computed: {
        isMobile() {
            return useNuxtApp().$isMobile?.value;
        }
    },
    methods: {
        useSimulateurStore,
        save() {
            this.$emit('update:contactValority', this.contactValorityTemp);
            this.$emit('update:show', false);
            this.$emit('resultat');
        }
    }
}
</script>

<style scoped lang="scss">
.height-mobile {
    height: calc(100vh - theme('height.menu'));
}

.btn-valority {
    &:after {
        @apply bg-white #{!important};
    }
}
</style>
