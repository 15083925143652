<template>
    <div :style="colorStyles.bg.secondary" class="rounded">
        <div class="flex items-center text-base pl-5 pr-4 py-[1.125rem]" @click="openAccordeon = !openAccordeon" :class="{'text-grey7': from === 'maslow'}">
            <icons-light-bulb-outline class="w-6 mr-[.625rem]" :style="colorStyles.text.primary"></icons-light-bulb-outline>

            Votre estimation en 4 étapes
            <icons-chevron-up-outline v-if="openAccordeon" class="w-6 text-[#8C8C8C] ml-auto"></icons-chevron-up-outline>
            <icons-chevron-down-outline v-else class="w-6 text-[#8C8C8C] ml-auto"></icons-chevron-down-outline>
        </div>

        <c-accordeon :open-accordeon="openAccordeon">
            <div class="text-base pl-6 pr-5 pb-[1.188rem]" :class="{'text-grey7': from === 'maslow'}">
                <div v-if="indexCurrentEtape === 1">
                    Votre âge permet de définir une
                    <span class="font-medium" :class="{'text-black': from === 'maslow'}" :style="from === 'valority' ? colorStyles.text.primary : ''">
                        durée de crédit
                    </span>
                    adaptée.

                    <div v-if="from === 'maslow'">
                        <br>
                        Nous proposons aussi aux
                        <span class="font-medium" :class="{'text-black': from === 'maslow'}" :style="from === 'valority' ? colorStyles.text.primary : ''">
                            expatriés
                        </span>
                        d’investir facilement et 100% en ligne sur Maslow.
                    </div>
                </div>

                <div v-if="indexCurrentEtape === 2">
                    Les
                    <span class="font-medium" :class="{'text-black': from === 'maslow'}" :style="from === 'valority' ? colorStyles.text.primary : ''">
                        autres sources de revenus
                    </span>
                    incluent les revenus locatifs, d’auto-entreprise, les pensions alimentaires, les allocations, etc.<br>
                    Lorsque vous indiquez vos revenus en net, notez bien le montant avant impôt.
                </div>

                <div v-if="indexCurrentEtape === 3">
                    Vous êtes en train de devenir propriétaire de votre résidence principale ? Vous avez acheté une
                    voiture à crédit ? Nous avons besoin de connaitre
                    <span class="font-medium" :class="{'text-black': from === 'maslow'}" :style="from === 'valority' ? colorStyles.text.primary : ''">
                        les crédits que vous continuerez à rembourser
                    </span>
                    en même temps que votre crédit immobilier.<br>
                    Le calcul ne prendra pas en compte les crédits qui se terminent dans moins de 24 mois.<br>
                    Si vous avez un crédit immobilier en cours qui se termine dans moins de 12 ans, il sera possible
                    d’ajuster à la hausse l’estimation que vous allez découvrir.
                </div>

                <div v-if="indexCurrentEtape === 4">
                    Le <span class="font-medium" :class="{'text-black': from === 'maslow'}" :style="from === 'valority' ? colorStyles.text.primary : ''">montant d’impôt est particulièrement important</span> pour notre
                    recommandation : il déterminera quelle fiscalité est la plus intéressante pour votre situation
                    et donc le bien qui vous correspond le mieux.
                </div>
            </div>
        </c-accordeon>
    </div>
</template>

<script setup>
const props = defineProps({
    indexCurrentEtape: {
        type: Number,
        required: true,
        default: 1
    },
    colorStyles: {
        type: Object,
        required: true
    },
    from: {
        type: String,
        required: true
    }
});

const openAccordeon = ref(false)
</script>

<style scoped lang="scss">

</style>
