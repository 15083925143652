<template>
    <c-modal
        :modelValue="show"
        ref="modal"
        @input="$emit('close', false)"
        :width="isMobile ? '100%' : '65%'"
    >
        <div class="modal bg-grey1 p-12 sm:pt-6 sm:pb-8 sm:px-1 sm:overflow-scroll shadow-2xl rounded-2xl"
            :class="{'height-mobile': isMobile}"
        >
            <div class="m-auto mb-12 sm:max-w-sm text-center">
                <h1 class="text-h3" :class="{'font-berlingske': from === 'maslow', 'font-playfair text-[#1F2945]': from === 'valority'}">
                    Comment trouver mon dernier avis d'impôts ?
                </h1>
            </div>

            <div class="flex gap-6 m-auto max-w-container">
                <div>
                    <div class="bg-grey2 rounded mb-12 p-6">
                        <div class="flex">
                            <div class="text-grey8 text-lg font-medium mr-4">
                                01
                            </div>

                            <div>
                                <div class="text-lg font-medium mb-4">
                                    Connectez-vous sur impots.gouv.fr
                                </div>

                                <a href="https://www.impots.gouv.fr/"
                                   target="_blank"
                                   class="px-4 py-3 mb-4 inline-block"
                                   :class="{'text-white font-medium': from === 'maslow', 'border rounded-full uppercase tracking-[1.6px] !font-bold btn-valority-inverted': from === 'valority'}"
                                   :style="from === 'maslow' ? colorStyles.bg.action : (colorStyles.text.action + colorStyles.border.action)"
                                >
                                    Accéder au site
                                    <icons-external-link-solid class="w-5 ml-3 inline-block"></icons-external-link-solid>
                                </a>

                                <div class="text-base text-grey8">
                                    Si vous n’avez pas de compte, cela signifie que vos avis d’imposition vous ont été
                                    envoyés par la poste. Vérifiez vos archives administratives.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex mb-12 px-4">
                        <div class="text-grey8 text-lg font-medium mr-4">
                            02
                        </div>

                        <div class="text-lg font-medium mb-4">
                            Ouvrez votre dernier avis d'imposition
                        </div>
                    </div>

                    <div class="flex px-4">
                        <div class="text-grey8 text-lg font-medium mr-4">
                            03
                        </div>

                        <div>
                            <div class="text-lg font-medium mb-4">
                                Localisez la ligne “Montant net de votre imposition” en page 2
                            </div>

                            <div class="text-base text-grey8 mb-4">
                                Il s'agit du nombre à indiquer sur le simulateur !
                            </div>

                            <c-button
                                class="bg-transparent rounded font-medium text-base border pt-3 pb-3"
                                :class="{'btn-valority text-white': from === 'valority'}"
                                :style="from === 'maslow' ? (colorStyles.border.action + colorStyles.text.action) : colorStyles.bg.action"
                                @click="close">
                                Retourner au simulateur
                            </c-button>
                        </div>
                    </div>
                </div>

                <div v-if="!isMobile">
                    <nuxt-img
                        format="webp"
                        densities="1x"
                        src="/images/simulation/impot.png"
                        alt="Image site impots.gouv.fr"
                    />
                </div>
            </div>
        </div>
    </c-modal>
</template>

<script>
export default {
    name: "ImpotsModal",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: false
        },
        from: {
            type: String,
            requiered: true
        },
        colorStyles: {
            type: Object,
            required: true
        }
    },
    model: {
        prop: 'show',
        event: 'show'
    },
    methods: {
        close() {
            this.$emit("close");
        }
    },
    computed: {
        isMobile() {
            return useNuxtApp().$isMobile?.value;
        }
    }
}
</script>

<style scoped lang="scss">
.height-mobile {
    height: calc(100vh - theme('height.menu'));
}
</style>
