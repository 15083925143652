<template>
    <div class="relative inline-block select-none" ref="select" v-click-outside="clickOustide">
        <div @click="toggleSelect(null)"
             id="input"
             tabindex="0"
             @keydown.down.prevent.stop="showSelect ? moveFocus(1) : toggleSelect(true)"
             @keydown.up.prevent.stop="showSelect ? moveFocus(-1) : toggleSelect(true)"
             @keydown.enter.prevent.stop="showSelect ? selectValue(items[itemFocus].value) : toggleSelect(true)"
             @keydown.esc.prevent.stop="toggleSelect(false)"
             class="flex items-center w-fit px-3 py-1.5 rounded-lg cursor-pointer"
             :style="colorOverride.bg.grey2"
             :class="{'border border-red bg-red-dimmed': errorMessage}"
        >
            <div class="text-lg mr-4"
                 :class="from === 'maslow' ? 'text-grey5' : 'text-[#A8A8A8]'"
                 :style="colorOverride !== null && selectedValue != null ? colorOverride.text.primary : ''"
            >
                {{ shownText }}
            </div>

            <icons-selector-outline
                class="w-4 h-4"
                :style="colorOverride.text.primary">
            </icons-selector-outline>
        </div>

        <div v-if="showSelect"
             ref="itemsView"
             class="absolute !min-w-fit whitespace-nowrap z-10 top-12 h-fit px-3 py-2 text-grey6 rounded-lg overflow-auto scroll-light"
             :style="colorOverride.bg.grey2"
        >
            <div
                class="cursor-pointer"
                v-for="(item, index) in items"
                :key="index"
                :class="{'itemActive' : itemFocus === index}"
                :style="colorOverride !== null && ((selectedValue === item.value && itemFocus === null) || itemFocus === index) ? colorOverride.text.primary : colorOverride !== null ?  + colorOverride.bg.grey2 : ''"
                @click.prevent.stop="() => {selectValue(item[itemValue])}"
                @mouseenter.prevent.stop="itemFocus = index"
                @focusout="$emit('focusout', $event)"
            >
                {{ item[itemText] || item }}
            </div>
        </div>

        <div class="absolute mt-0.5 text-red-error form-error-display text-base"
             v-if="showValidationErrors && errorMessage">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script setup>
import {toRef,} from 'vue';
import {useField} from 'vee-validate';

const props = defineProps({
    modelValue: {},
    placeholder: {
        type: [String, null],
        required: false,
        default: null
    },
    items: {
        type: [Array, null],
        required: true,
        default: () => {
            return []
        }
    },
    itemValue: {
        type: String,
        default: "value"
    },
    itemText: {
        type: String,
        default: "libelle"
    },
    vid: {
        type: String
    },
    rules: {
        type: [String, Object],
        default: ""
    },
    showValidationErrors: {
        type: Boolean,
        default: false
    },
    colorOverride: {
        type: Object,
        default: null
    },
    from: {
        type: String,
        default: 'maslow'
    }
});

const vid = toRef(props, 'vid');

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
} = useField(vid, props.rules, {
    initialValue: props.modelValue,
    syncVModel: true
});

defineEmits(['input', 'update:modelValue', 'focusout']);
</script>

<script>
export default {
    name: "Select",
    data() {
        return {
            selectedValue: null,
            showSelect: false,
            itemFocus: null,
        }
    },
    watch: {
        modelValue: {
            handler() {
                this.selectedValue = this.modelValue;
            },
            immediate: true
        }
    },
    methods: {
        toggleSelect(force) {
            this.itemFocus = this.selectedValue ? this.items.findIndex((item)=>item.value === this.selectedValue) : 0;

            if (force != null) {
                this.showSelect = force;

                return;
            }

            this.showSelect = !this.showSelect;
        },
        selectValue(value) {
            this.selectedValue = value;

            this.$emit("update:modelValue", this.selectedValue);

            this.toggleSelect(false);
        },
        clickOustide() {
            if (this.showSelect === true) {
                this.toggleSelect(false);
            }
        },
        findItemByValue(value) {
            let foundItem = null;

            this.items.forEach(item => {
                if (item[this.itemValue] != null && item[this.itemValue] === value) {
                    foundItem = item;
                }
            });

            return foundItem ?? this.items[value];
        },
        moveFocus(sens) {
            this.itemFocus = (((this.itemFocus + sens) % this.items.length) + this.items.length) % this.items.length;

            if (this.$refs?.itemsView?.querySelector('.itemActive')) {
                if (sens > 0) {
                    if (this.$refs.itemsView.querySelector('.itemActive').nextElementSibling) {
                        this.$refs.itemsView.querySelector('.itemActive').nextElementSibling.scrollIntoViewIfNeeded(false);
                    } else {
                        this.$refs.itemsView.querySelector('.itemActive').scrollIntoViewIfNeeded(false);
                    }
                } else {
                    if (this.$refs.itemsView.querySelector('.itemActive').previousElementSibling) {
                        this.$refs.itemsView.querySelector('.itemActive').previousElementSibling.scrollIntoViewIfNeeded(false);
                    } else {
                        this.$refs.itemsView.querySelector('.itemActive').scrollIntoViewIfNeeded(false);
                    }
                }
            }
        }
    },
    computed: {
        shownText() {
            if (this.selectedValue == null) {
                return this.placeholder;
            }

            let item = this.findItemByValue(this.selectedValue);

            return item[this.itemText] ?? item;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
