<template>
    <c-modal
        v-model="showModal"
        ref="modal"
        @close="$emit('update:show', false)"
        :width="isMobile ? '100%' : '35%'"
    >
        <div class="modal bg-grey1 px-8 py-10 sm:p-6 sm:overflow-scroll shadow-2xl rounded-2xl"
             :class="{'height-mobile': isMobile}"
        >
            <div class="flex flex-col justify-center items-center m-auto sm:max-w-sm sm:overflow-x-scroll">
                <h1 class="text-tangerine text-lg text-center mb-2">
                    Merci de renseigner vos coordonnées pour recevoir votre résultat
                </h1>

                <div class="text-center sm:px-0 px-2 w-8/12 sm:w-full">
                    Vous accéderez aussi aux appartements correspondants
                    à votre capacité d’emprunt.
                </div>

                <c-form @submit="save">
                    <div class="w-fit flex flex-col items-center gap-6 mb-8 sm:mb-4 mt-5">

                        <div class="w-10/12 grid grid-cols-2 gap-4">
                            <c-text-field
                                v-model="nomTemp"
                                vid="nom"
                                rules="required"
                                show-validation-errors
                                label="Nom*">
                            </c-text-field>

                            <c-text-field
                                v-model="prenomTemp"
                                vid="prenom"
                                rules="required"
                                show-validation-errors
                                label="Prénom*">
                            </c-text-field>

                            <c-input-telephone
                                v-model="mobileTemp"
                                vid="telephonePortable"
                                required
                                show-validation-errors
                                return-value="number"
                                label="Téléphone portable*">
                            </c-input-telephone>

                            <c-text-field
                                v-model="codePostalTemp"
                                vid="codePostal"
                                rules="required"
                                show-validation-errors
                                label="Code postal*">
                            </c-text-field>

                            <c-text-field
                                v-model="emailTemp"
                                vid="email"
                                class="col-span-2"
                                rules="required|email"
                                show-validation-errors
                                label="Email*">
                            </c-text-field>

                            <c-input-checkbox-field
                                v-model="optinAccepted"
                                key="simuOptin"
                                id="simuOptin"
                                vid="simuOptin"
                                class="col-span-2"
                                show-validation-errors
                                separate-error
                                rules="required"
                            >
                                <div class="text-sm">
                                    J'accepte que mes données soient traitées par Maslow dans le cadre de ma demande de
                                    simulation et de la relation commerciale qui pourrait en découler
                                </div>
                            </c-input-checkbox-field>
                        </div>

                        <c-button
                            :loading="useSimulateurStore().loading"
                            :disabled="useSimulateurStore().loading"
                            class="flex items-center text-base text-white bg-tangerine border border-tangerine sm:!px-3 sm:!py-4">
                            Voir mon résultat et les apparts correspondants
                        </c-button>
                    </div>
                </c-form>

                <div class="text-grey6 text-xs">
                    Dans un souci de sécurité, en cliquant sur le bouton « Voir mon résultat et les apparts
                    correspondants », la collecte de votre numéro de téléphone a vocation à ce stade à vous envoyer un
                    SMS ou un message Whatsapp de validation de votre simulation. Les informations collectées font
                    l’objet d’un traitement automatisé et informatisé. Ces traitements sont rendus nécessaires pour
                    répondre à votre demande de contact et sont fondés sur votre consentement préalable.

                    Pour toute information complémentaire relative au traitement de vos données personnelles, consultez
                    notre politique de protection des données personnelles.

                    Les données sont conservées pendant une durée de trois ans à compter du dernier contact de votre
                    part. Conformément à la loi du 6 janvier 1978 et au Règlement Général sur la Protection des Données
                    (RGPD) du 27 avril 2016 n°2016/679, vous disposez d’un droit d’accès, de rectification,
                    d’opposition, d’effacement, de limitation du traitement de vos données et de portabilité de
                    celles-ci à formuler auprès du responsable de traitement, à l’adresse suivante : Maslow, Service
                    RGPD, 94 quai Charles de Gaulle 69006 LYON. Vous pouvez également adresser une réclamation auprès de
                    la Commission Nationale de l’Informatique et des Libertés – 3 place de Fontenoy – TSA 80715 – 75334
                    PARIS Cedex 07
                </div>
            </div>
        </div>
    </c-modal>
</template>

<script>
import {useSimulateurStore} from "~/store/simulateur";

export default {
    name: "ContactModal",
    emits: ['update:mobile', 'update:nom', 'update:prenom', 'update:show', 'update:email', 'update:codePostal', 'resultat'],
    props: {
        mobile: {
            type: String,
        },
        nom: {
            type: String,
        },
        prenom: {
            type: String,
        },
        email: {
            type: String,
        },
        codePostal: {
            type: String,
        },
        show: {
            type: Boolean,
        },
        closable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mobileTemp: this.mobile,
            prenomTemp: this.prenom,
            emailTemp: this.email,
            nomTemp: this.nom,
            codePostalTemp: this.codePostal,
            formattedNumber: null,
            phoneNumberInvalid: false,
            showModal: null,
            optinAccepted: false,
        }
    },
    watch: {
        show() {
            this.showModal = this.show;
        },
        mobileTemp() {
            if (this.mobileTemp !== null) {
                this.formattedNumber = this.mobileTemp.replace('+', '');
            }
        },
    },
    computed: {
        isMobile() {
            return useNuxtApp().$isMobile?.value;
        }
    },
    methods: {
        useSimulateurStore,
        save() {
            this.$emit('update:mobile', this.formattedNumber);
            this.$emit('update:nom', this.nomTemp);
            this.$emit('update:prenom', this.prenomTemp);
            this.$emit('update:email', this.emailTemp);
            this.$emit('update:codePostal', this.codePostalTemp);
            this.$emit('update:show', false);
            this.$emit('resultat');
        }
    }
}
</script>

<style scoped lang="scss">
.height-mobile {
    height: calc(100vh - theme('height.menu'));
}
</style>
